import logoIcon from '../assets/icons/logos/giveLogo.svg';
import loginIcon from '../assets/icons/fontawesome/arrow-right-to-bracket-solid.svg';
import logoutIcon from '../assets/icons/fontawesome/arrow-right-from-bracket-solid.svg';
import userPlusIcon from '../assets/icons/fontawesome/user-plus-solid.svg';
import resetIcon from '../assets/icons/fontawesome/lock-solid.svg';
import purchaseIcon from '../assets/icons/fontawesome/store-solid.svg';
import dashboardIcon from '../assets/icons/fontawesome/chart-line-solid.svg';
import keyIcon from '../assets/icons/fontawesome/key-solid.svg';
import userCircle from '../assets/icons/fontawesome/user-circle-solid.svg';
import gearIcon from '../assets/icons/fontawesome/gear.svg';
import supportIcon from '../assets/icons/fontawesome/envelope-regular.svg';
import walletIcon from '../assets/icons/fontawesome/wallet-solid.svg';
import rewardsIcon from '../assets/icons/fontawesome/medal-solid.svg';
import nodesIcon from '../assets/icons/fontawesome/circle-nodes-solid.svg';
import { brand } from '../brand/brand';

export const routes = {
  login: {
    path: '/login',
    key: 'login',
    value: 'Log In',
    icon: loginIcon.src,
  },
  logout: {
    path: '/logout',
    key: 'logout',
    value: 'Logout',
    icon: logoutIcon.src,
  },
  signup: {
    path: '/signup',
    key: 'signup',
    value: 'Sign Up',
    icon: userPlusIcon.src,
  },
  emailCheck: {
    path: '/email-check',
    key: 'email-check',
    value: 'Email check',
  },
  home: {
    path: `${brand.loggedOutHomeRoute}`,
    key: 'home',
    value: 'Home',
    icon: logoIcon.src,
  },
  loggedInHome: {
    path: `${brand.loggedInHomeRoute}`,
    key: 'home',
    value: 'Home',
    icon: logoIcon.src,
  },
  store: {
    path: '/store',
    key: 'store',
    value: 'Store',
    icon: purchaseIcon.src,
  },
  cart: {
    path: '/cart',
    key: 'cart',
    value: 'Cart',
  },
  checkout: {
    path: '/checkout',
    key: 'checkout',
    value: 'Cart',
  },
  dashboard: {
    path: '/dashboard',
    key: 'dashboard',
    value: 'Dashboard',
    icon: dashboardIcon.src,
  },
  blockchain: {
    path: '/dashboard/blockchain',
    key: 'blockchain',
    value: 'Blockchain',
    icon: nodesIcon.src,
  },
  rewards: {
    path: '/dashboard/rewards',
    key: 'rewards',
    value: 'Rewards',
    icon: rewardsIcon.src,
  },
  wallet: {
    path: '/dashboard/wallet',
    key: 'wallet',
    value: 'Wallet',
    icon: walletIcon.src,
  },
  emailConfirmation: {
    path: '/email-confirmation',
    key: 'email-confirmation',
    value: 'Email Confirmation',
  },
  agreements: {
    path: '/agreements',
    key: 'agreements',
    value: 'Agreements',
  },
  status: {
    path: '/status',
    key: 'status',
    value: 'Status',
  },
  referFriend: {
    path: '/refer-a-friend',
    key: 'refer-a-friend',
    value: 'Refer a Friend',
    icon: userPlusIcon.src,
  },
  setupWallet: {
    path: '/setup-wallet',
    key: 'setup-wallet',
    value: 'Setup wallet',
  },
  resetPassword: {
    path: '/reset-password',
    key: 'reset-password',
    value: 'Reset password',
    icon: resetIcon.src,
  },
  changePassword: {
    path: '/change-password',
    key: 'change-password',
    value: 'Change password',
    icon: resetIcon.src,
  },
  changeWalletPasscode: {
    path: '/change-wallet-passcode',
    key: 'change-wallet-passcode',
    value: 'Change Wallet Passcode',
    icon: keyIcon.src,
  },
  support: {
    path: '/support',
    key: 'support',
    value: 'Support',
    icon: supportIcon.src,
  },
  forgotPassword: {
    path: '/forgot-password',
    key: 'forgot-password',
    value: 'Forgot Password',
  },
  manageAccount: {
    path: '/settings',
    key: 'manage-account-profile',
    value: 'Settings',
    alias: 'Profile',
    icon: userCircle.src,
  },
  manageAccountResetPassword: {
    path: '/settings/reset-password',
    key: 'manage-account-reset-password',
    value: 'Reset Password',
  },
  manageAccountChangePasscode: {
    path: '/settings/change-passcode',
    key: 'manage-account-change-passcode',
    value: 'Change Passcode',
  },
  manageAccountTwoFactorAuth: {
    path: '/settings/two-factor-auth',
    key: 'manage-account-two-factor-auth',
    value: 'Two Factor Auth',
  },
  settings: {
    path: '/settings',
    key: 'settings',
    value: 'Settings',
    icon: gearIcon.src,
  },
};
