import { combineReducers, configureStore } from '@reduxjs/toolkit';

import walletReducer from '~src/hooks/slices/walletSlice';
import purchaseReducer from '~src/hooks/slices/purchaseSlice';

const combineReducer = combineReducers({
  walletReducer,
  purchaseReducer,
});

export const store = configureStore({
  reducer: {
    reducer: combineReducer,
  },
});
