import { useEffect, useRef } from 'react';
import { useToast } from '~src/hooks/useToast';

function CheckmarkIcon() {
  return (
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
      <svg
        aria-hidden="true"
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Check icon</span>
    </div>
  );
}

function InfoIcon() {
  return (
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
      <svg
        aria-hidden="true"
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0.5 1 14 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Info icon</span>
    </div>
  );
}

function ErrorIcon() {
  return (
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-mint-600 bg-giveRed-100 rounded-lg dark:bg-giveRed-800 dark:text-giveRed-200">
      <svg
        aria-hidden="true"
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Error icon</span>
    </div>
  );
}

function WarningIcon() {
  return (
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-yellow-500 bg-yellow-100 rounded-lg dark:bg-yellow-700 dark:text-yellow-200">
      <svg
        aria-hidden="true"
        className="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Warning icon</span>
    </div>
  );
}

const Toast = ({ toastId, message, status, timeout, onClose, ...props }) => {
  const { removeToast } = useToast();

  const ref = useRef(null);

  let icon;
  let colorClasses;
  let buttonColorClasses;
  switch (status) {
    case 'success':
      icon = <CheckmarkIcon />;
      colorClasses = 'text-green-900 bg-green-400';
      buttonColorClasses = 'bg-green-400 hover:bg-green-300 text-green-900';
      break;
    case 'error':
      icon = <ErrorIcon />;
      colorClasses = 'text-giveRed-900 bg-giveRed-300';
      buttonColorClasses =
        'bg-giveRed-300 hover:bg-brand-200 text-giveRed-900 hover:text-giveRed-900';
      break;
    case 'warning':
      icon = <WarningIcon />;
      colorClasses = 'text-yellow-900 bg-yellow-300';
      buttonColorClasses =
        'bg-yellow-300 hover:bg-yellow-200 text-yellow-900 hover:text-yellow-900';
      break;
    default:
      icon = <InfoIcon />;
      colorClasses = 'text-blue-900 bg-blue-400';
      buttonColorClasses = 'bg-blue-400 text-blue-900 hover:bg-blue-300';
      break;
  }

  useEffect(() => {
    if (timeout && !ref.current) {
      ref.current = setTimeout(() => {
        removeToast(toastId);
      }, timeout);
    }
  }, [toastId, timeout, removeToast]);

  return (
    <div
      className={`flex items-center w-full p-4 mb-4 ${colorClasses} rounded-lg shadow`}
      role="alert"
      {...props}
    >
      {icon}
      <div className="ml-3 text-sm font-normal">{message}</div>
      <button
        type="button"
        className={`ml-auto -mx-1.5 -my-1.5 ${buttonColorClasses} rounded-lg focus:ring-2 focus:ring-giveGray-300 p-1.5  inline-flex h-8 w-8`}
        onClick={onClose}
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default Toast;
