import { createSlice } from '@reduxjs/toolkit';

//object structure for passing node info
const nodesVaultData = {
  activeNodes: {
    headingText: 'Total Active Nodes',
    dataTag: 'vaultNodeActive',
    activeNodes: 0,
    totalNodes: 0,
  },
  smartNodes: {
    headingText: 'Smart Nodes',
    dataTag: 'vaultNodeSmart',
    activeNodes: 0,
    totalNodes: 0,
  },
  liteNodes: {
    headingText: 'Lite Nodes',
    dataTag: 'vaultNodeLite',
    activeNodes: 0,
    totalNodes: 0,
  },
};

const initialState = {
  products: [],
  nodesVaultData,
  nodesState: {
    userHasNodes: false,
    nodesNeedSetup: false,
  },
};

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setPurchasesState: (state, action) => {
      //total active nodes running
      state.nodesVaultData.activeNodes.activeNodes =
        action.payload.totalActiveNodes.licensesUsed || 0;
      //total nodes owned
      state.nodesVaultData.activeNodes.totalNodes = action.payload.totalNodes || 0;
      //total smart nodes running
      state.nodesVaultData.smartNodes.activeNodes =
        action.payload.totalActiveNodes.smartUsed || 0;
      //total smart owned
      state.nodesVaultData.smartNodes.totalNodes =
        action.payload.userNodes.productTotals.smartNode || 0;
      //total lite nodes running
      state.nodesVaultData.liteNodes.activeNodes =
        action.payload.totalActiveNodes.liteUsed || 0;
      //total lite nodes owned
      state.nodesVaultData.liteNodes.totalNodes =
        action.payload.userNodes.productTotals.liteNode || 0;
      state.nodesState.userHasNodes = action.payload.totalNodes > 0;
      state.nodesState.nodesNeedSetup = action.payload.totalNodes > 0;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPurchasesState, setProducts } = purchaseSlice.actions;

export default purchaseSlice.reducer;
