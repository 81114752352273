import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@nerdcoresdk/nerd-core-ui';
import { AuthProvider } from '~src/providers/AuthProvider';
import { ProtectedRoute } from '~src/components/Routes/ProtectedRoute';
import { StrictlyPublicRoute } from '~src/components/Routes/StrictlyPublicRoute';
import { EnrollmentRoute } from '~src/components/Routes/EnrollmentRoute';
import { RoleProtectedRoute } from '~src/components/Routes/RoleProtectedRoute';
import { Layout } from '~src/components/Layout/Layout';
import { ExchangeProvider } from '~src/components/ExchangeProvider';
import { store } from '~src/utils/store';
import client from '~src/utils/wagmi-client';
import { WagmiConfig } from 'wagmi';
import StoreProvider from '~src/providers/StoreProvider';
import CartProvider from '~src/providers/CartProvider';

import '../styles/nerd-base.css';

import { brand } from '~src/brand/brand';
import './_app.css';
import { ToastProvider } from '~src/providers/ToastProvider';
import ToastContainer from '~src/components/Toast/ToastContainer';
import EngageWidget from '~src/components/Engage/widget';

function Protector({ Component, pageProps }) {
  const {
    enrollmentRoute,
    protectedRoute,
    strictlyPublicRoute,
    roleProtectedRoute,
    requiredRole,
  } = Component;
  if (enrollmentRoute) {
    return (
      <EnrollmentRoute>
        <Component {...pageProps} />
      </EnrollmentRoute>
    );
  } else if (protectedRoute) {
    return (
      <ProtectedRoute>
        <Component {...pageProps} />
      </ProtectedRoute>
    );
  } else if (strictlyPublicRoute) {
    return (
      <StrictlyPublicRoute>
        <Component {...pageProps} />
      </StrictlyPublicRoute>
    );
  } else if (roleProtectedRoute) {
    return (
      <RoleProtectedRoute requiredRole={requiredRole}>
        <Component {...pageProps} />
      </RoleProtectedRoute>
    );
  } else {
    return <Component {...pageProps} />;
  }
}

function App({ Component, pageProps }) {

  return (
    <WagmiConfig client={client}>
      <Provider store={store}>
        <AuthProvider>
          <StoreProvider>
            <CartProvider>
              <ToastProvider>
                <ThemeProvider theme={brand.theme}>
                  <ToastContainer />
                  <ExchangeProvider>
                    <Layout>
                      <Protector Component={Component} pageProps={pageProps} />
                    </Layout>
                  </ExchangeProvider>
                </ThemeProvider>
              </ToastProvider>
            </CartProvider>
          </StoreProvider>
        </AuthProvider>
      </Provider>
    </WagmiConfig>
  );
}

export default App;
