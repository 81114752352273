import axios from 'axios';
import { useCallback } from 'react';

export function useFetcher({ user }) {
  const fetcher = useCallback(
    ({ method = 'GET', url, data = {} }) => {
      const { accessToken } = user;

      let contentType = "application/json";
      let body = null;
      if (data instanceof FormData) {
        contentType = "multipart/form-data";
        body = data;
      }
      else if (data) {
        body = { ...data };
      }

      return axios({
        method,
        url,
        data: body,
        headers: {
          'Content-Type': contentType,
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      })
        .then(({ data }) => data)
        .catch((error) => {
          console.log('fetcher catch log', {error})
          throw error
        });
    },
    [user]
  );

  return fetcher;
}

