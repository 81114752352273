import { useToast } from '~src/hooks/useToast';
import Toast from './Toast';

export default function ToastContainer() {
  const { toasts, removeToast } = useToast();

  return (
    <div className="absolute top-0 left-1/3 w-1/3 z-50 mt-20 flex flex-col justify-center content-center">
      {toasts.map((toast) => {
        return (
          <Toast
            key={toast.id}
            toastId={toast.id}
            id={'toast-' + toast.id}
            message={toast.message}
            status={toast.status}
            timeout={toast.timeout}
            onClose={(e) => {
              e.preventDefault();
              removeToast(toast.id);
            }}
          />
        );
      })}
    </div>
  );
}
