import React from 'react';

const LoadingEllipses = () => {
  return (
    <div className="flex items-center justify-center space-x-4 w-full ml-4">
      <div className="w-2 h-2 bg-giveGray-400 rounded-full animate-[pulse_1s_ease-in-out_infinite]"></div>
      <div className="w-2 h-2 bg-giveGray-400 rounded-full animate-[pulse_1s_ease-in-out_infinite]"></div>
      <div className="w-2 h-2 bg-giveGray-400 rounded-full animate-[pulse_1s_ease-in-out_infinite]"></div>
    </div>
  );
};

export default LoadingEllipses;
