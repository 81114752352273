import { createContext, useEffect, useMemo, useState } from 'react';
import { onIdTokenChanged } from 'firebase/auth';
import { configure } from '@nerdcoresdk/nerd-core-auth';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { config } from '~config';
import { useFetcher } from '~src/hooks/useFetcher';
import { setTokenRefreshInterval } from '~src/utils';
import { clearWallet } from '~src/hooks/slices/walletSlice';

const {
  confirmEmail,
  createRecaptcha,
  disableMFA,
  enableMFA,
  enrichToken,
  fetchNodePurchaseAgreements,
  fetchUserAgreements,
  firebaseAuth,
  refreshTheToken,
  resetPassword,
  resetPasswordFromEmail,
  sendForgotPasswordEmail,
  sendLoginMFA,
  sendVerificationEmail,
  signin,
  signup,
  signout,
  submitUserAgreements,
  updateFirebaseToken,
  validateMFACode,
  verifyAuthentication,
} = configure({
  environment: config.environment,
  firebaseConfig: config.firebase,
  brandConfig: config.brand,
  coreConfig: config.core,
});

const authStatuses = {
  PENDING: 'pending',
  SIGNED_IN: 'signedIn',
  SIGNED_OUT: 'signedOut',
};

const enrollmentPhases = {
  PENDING: 'pending',
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
};

const times = {
  //👇 DO NOT SET FOR MORE THAN 59 MINUTES (firebase default token expiration is 60 minutes)
  TOKEN_REFRESH_RATE: 1000 * 60 * 50,
};

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [decodedToken, setDecodedToken] = useState({});
  const [status, setStatus] = useState(authStatuses.PENDING);
  const [enrollmentPhase, setEnrollmentPhase] = useState(enrollmentPhases.PENDING);
  const [refreshInterval, setRefreshInterval] = useState('');

  const fetcher = useFetcher({ user });

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, (_user) => {
      if (!_user) {
        refreshInterval && clearInterval(refreshInterval);
        setRefreshInterval('');
        setUser({});
        setStatus(authStatuses.SIGNED_OUT);
        setEnrollmentPhase(enrollmentPhases.PENDING);
        dispatch(clearWallet(undefined));
      } else {
        const tmpDecodedToken = jwtDecode(_user.accessToken);
        setDecodedToken(tmpDecodedToken);
        const { emailVerified, permissions, agreementsSigned, exp } = tmpDecodedToken;
        if (emailVerified && permissions) {
          setUser(_user);
          setStatus(authStatuses.SIGNED_IN);

          const tokenRefreshInterval = setTokenRefreshInterval.getInstance({
            user: _user,
            refreshTheToken,
            currentExpiration: exp,
            tokenRefreshRate: times.TOKEN_REFRESH_RATE,
          });
          setRefreshInterval(tokenRefreshInterval);
          agreementsSigned
            ? setEnrollmentPhase(enrollmentPhases.COMPLETED)
            : setEnrollmentPhase(enrollmentPhases.INCOMPLETE);
        }
      }
    });
    return () => unsubscribe();
  }, [setUser, setStatus, status, setEnrollmentPhase, refreshInterval, dispatch]);

  const isAdmin = () => {
    let tmpRoles = decodedToken?.roles ?? [];
    return tmpRoles.includes("Admin") || tmpRoles.includes("Super Admin") || tmpRoles.includes("System Admin");
  }

  const hasRole = (roleName) => {
    let tmpRoles = decodedToken?.roles ?? [];
    return tmpRoles.includes(roleName) || tmpRoles.map(tmpRole => tmpRole.toLowerCase()).includes(roleName.toLowerCase());
  }

  const value = useMemo(
    () => ({
      authStatuses,
      confirmEmail,
      createRecaptcha,
      disableMFA,
      enableMFA,
      enrichToken,
      enrollmentPhase,
      enrollmentPhases,
      fetcher,
      fetchUserAgreements,
      fetchNodePurchaseAgreements,
      firebaseAuth,
      refreshTheToken,
      resetPassword,
      resetPasswordFromEmail,
      sendForgotPasswordEmail,
      sendLoginMFA,
      sendVerificationEmail,
      signin,
      signout,
      signup,
      status,
      submitUserAgreements,
      updateFirebaseToken,
      validateMFACode,
      verifyAuthentication,
      user,
      decodedToken,
      permissions: decodedToken?.permissions ?? [],
      roles: decodedToken?.roles ?? [],
      isAdmin,
      hasRole
    }),
    [user, status, enrollmentPhase, fetcher, decodedToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
