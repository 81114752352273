export const getCartTotals = (arr) =>
  arr?.reduce(
    (
      { cartSubtotal = 0, cartQuantity = 0, cartEthSubtotal = 0 },
      { ethSubtotal, quantity, subtotal }
    ) => {
      return {
        cartSubtotal: cartSubtotal + Number(subtotal),
        cartEthSubtotal: cartEthSubtotal + Number(ethSubtotal),
        cartQuantity: cartQuantity + Number(quantity),
      };
    },
    0
  );

// hardcoded default limits on nodes
export const defaultLimits = (name) =>
  ({
    'Lite Node': 10000,
    'Smart Node': 100,
  }[name]);
