import styles from './layout.module.css';

export function Layout({ className="", children }) {

  return (
    <div className={className + " " + styles['app-container']}>
      <div className={styles['app-body']}>{children}</div>
    </div>
  );
}
