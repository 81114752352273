import { config } from '~config';

export function getCoinConversion({ token, currency }) {
  return {
    method: 'GET',
    url: `${config.core.ethConnectUrl}/api/${token}/price/${currency}`,
  };
}

/**
 * ONLY USE WITH BITCOIN
 * @param token
 * @returns {{method: string, url: string}}
 */
export function getGasFee({ token }) {
  return {
    method: 'GET',
    url: `${config.core.ethConnectUrl}/api/${token}/fee`,
  };
}

export function getTransactionByHash({hash}) {
  return {
    method: 'GET',
    url: `${config.core.ethConnectUrl}/api/transaction/${hash}`
  }
}

export function getTransactionReceiptByHash({hash}) {
  return {
    method: 'GET',
    url: `${config.core.ethConnectUrl}/api/transaction/${hash}/receipt`
  }
}