import React, { useContext, useEffect, useState } from 'react';
// Hooks
import { useAuth } from '../hooks/useAuth';
// Utils
import { getAvailableProducts, getProductByID } from '../resources/shopping-service.resource';

const Store = React.createContext({});

export const useStoreContext = () => useContext(Store);

const initStoreState = {
  storeError: { error: false, message: '' },
  storeItems: [],
};

const StoreProvider = ({ children }) => {
  const [{ storeError, storeItems }, setStoreState] = useState(initStoreState);
  const { fetcher } = useAuth();

  const handleSetStoreState = (storeData) =>
    setStoreState((prev) => ({ ...prev, ...storeData }));

  useEffect(() => {
    // get store products
    getProducts();
  }, []);

  const getProducts = async () =>
    await fetcher(getAvailableProducts())
      .then((products) => {
        let productsObj = {};
        products.forEach((product) => {
          const id = product.id;
          productsObj[id] = { ...product };
        });
        handleSetStoreState({
          storeItems: productsObj,
        });
      })
      .catch(() =>
        handleSetStoreState({
          storeError: { error: true, message: 'Error getting products' },
        })
      );

  const getProduct = async (productId) =>
    await fetcher(getProductByID({ productId })).catch(() =>
      handleSetStoreState({
        storeError: { error: true, message: `Error getting product ${productId}` },
      })
    );

  return (
    <Store.Provider
      value={{
        storeError,
        storeItems,
        getProduct,
      }}
    >
      {children}
    </Store.Provider>
  );
};

export default StoreProvider;
