import { nanoid } from '@reduxjs/toolkit';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

export const ToastContext = createContext();

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);
  const timeoutHandles = [];

  useEffect(() => {
    return () => {
      for (let handle in timeoutHandles) {
        clearTimeout(handle);
      }
    };
  }, []);

  const removeToast = useCallback(
    (toastId) => {
      setToasts((currToasts) => {
        let foundIndex = currToasts.findIndex((toast) => toast.id === toastId);

        if (foundIndex >= 0) {
          return [...currToasts.slice(0, foundIndex), ...currToasts.slice(foundIndex + 1)]
        }
        return currToasts;
      });
    },
    [toasts.length, setToasts]
  );

  const addToast = useCallback(
    (message, status, timeout) => {
      let toast = {
        id: nanoid(),
        message,
        status,
        timeout,
      };

      setToasts([...toasts, toast]);
    },
    [toasts, setToasts, removeToast]
  );

  const contextValue = useMemo(() => {
    return {
      toasts,
      addToast,
      removeToast,
    };
  }, [toasts, addToast, removeToast]);

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
}
