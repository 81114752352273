import axios from 'axios';
import { getCoinConversion, getTransactionByHash, getTransactionReceiptByHash } from './eth-connect-service.resource';
import { getGiveUsdPrice } from '~src/resources/give-api.resource';

export const requestCoinConversion = async (token, currency) => {
    //await delay(2500);
    if (token === "GIVE") {
        return (await axios(getGiveUsdPrice())).data?.price?.usd || null;
    }
    return (await axios(getCoinConversion({token, currency}))).data;
}

export const requestTransactionByHash = async (hash) => {
    return (await axios(getTransactionByHash({hash}))).data;
}

export const requestTransactionReceiptByHash = async (hash) => {
    return (await axios(getTransactionReceiptByHash({hash}))).data;
}