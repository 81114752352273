import { chain, configureChains, createClient } from 'wagmi';

import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';
import { alchemyProvider } from 'wagmi/providers/alchemy';

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { config } from '../../config';

let activeChains = [chain.goerli];
if (config.environment === "production") {
  activeChains = [chain.mainnet];
}

const { chains, provider, webSocketProvider } = configureChains(activeChains, [
  alchemyProvider({apiKey: config.web3.alchemyApiKey}),
  infuraProvider({apiKey: config.web3.infuraApiKey}),
  publicProvider()
]);

// Set up client
const client = createClient({
  appName: "giveblockchain.io",
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Browser / Extension',
        shimDisconnect: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'giveblockchain.io',
      },
    })
  ],
  provider,
  webSocketProvider
});

export default client;