import { useRouter } from 'next/router';

export default function Link({ children, disabled = false, className, ...props }) {
  const router = useRouter();

  let classes = [];
  if (disabled) {
    classes.push("cursor-not-allowed");
  }

  function handleClick(e) {
    e.preventDefault();

    if (!disabled) {
      if (props.href) {
        router.push(props.href);
      }
    }

    return false;
  }

  return (
    <a className={className + " " + classes.join(" ")} {...props} onClick={handleClick}>
      {children}
    </a>
  );
}
