import { useState } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from '~src/hooks/useAuth';
import LoadingEllipses from '~src/components/LoadingEllipses';
import Link from '~src/components/Link';

export function StrictlyPublicRoute({ children }) {
  const { status, authStatuses, signout } = useAuth();

  const handleSignoutClick = (e) => {
    e.preventDefault();
    signout();
  }

  if (status === authStatuses.SIGNED_OUT) {
    return children;
  }
  else if (status === authStatuses.PENDING) {
    return (
      <div className="flex flex-col mt-20 py-20 justify-center items-center">
        <h1 className="text-xl font-semibold mb-3">Loading...</h1>
        <LoadingEllipses />
      </div>
    );
  }
  else {
    return (
      <div className="flex mt-20 py-20 justify-center">
        <p>You must be signed out to view this page. &nbsp;
          <Link href={"/dashboard"} className="underline cursor-pointer">Go to the Dashboard</Link> &nbsp; or &nbsp;
          <a href={"#"} onClick={handleSignoutClick} className="underline cursor-pointer">Sign out</a></p>
      </div>
    );
  }
}
