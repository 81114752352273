import { config } from '~config';

export function getUserWalletItem({ symbol }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/wallets/${symbol}`,
  };
}

export function getTransactionHistory({ symbol, page, pageSize, sortBy, sortDir }) {
  let sortByParam = `${sortBy}+${sortDir}`;
  sortByParam = sortBy !== 'timestamp' ? `${sortByParam},timestamp+desc` : sortByParam;

  const queryParams = `?page=${page - 1}&pageSize=${pageSize}&sortBy=${sortByParam}`;
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/wallets/transactions/${symbol}${queryParams}`,
  };
}

export function getUserRewardsHistory({ sortBy, pageSize, page, sortDir }) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/rewards/?sortBy=${sortBy}+${sortDir}&pageSize=${pageSize}&page=${page}`,
  };
}

export function getUserRewardsTotals() {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/api/rewards/summary`,
  };
}

export function sendTransaction({ amount, symbol, to, walletPassword, gasFee }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/transactions`,
    data: { amount, symbol, to, walletPassword, gasFee },
  };
}

export function mintUserRewards(passCode) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/rewards/mint-rewards`,
    data: {
      passCode: passCode,
    },
  };
}

export function getWalletMnemonicRecoveryPhrase() {
  return {
    url: `${config.core.walletServiceUrl}/api/wallets/mnemonic`,
  };
}

export function createWallet({ walletPasscode, walletMnemonicRecoveryPhrase }) {
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/wallets`,
    data: {
      mnemonic: walletMnemonicRecoveryPhrase,
      passcode: walletPasscode,
    },
  };
}

export function changeWalletPasscode({ mnemonic, newPasscode }) {
  return {
    method: 'PUT',
    url: `${config.core.walletServiceUrl}/api/wallets/passcode`,
    data: {
      mnemonic,
      newPasscode,
    },
  };
}

export function sendBridgeTransaction({ amount, walletPassword }) {
  // const isLegacyFlag = getFeatureFlag({ name: 'nerd-core-legacy' });
  return {
    method: 'POST',
    url: `${config.core.walletServiceUrl}/api/bridge`,
    data: { amount, walletPassword },
  };
}

export function getGasFee(symbol) {
  return {
    method: 'GET',
    url: `${config.core.walletServiceUrl}/v1.0/transactions/fee/${symbol}`
  }
}
