import { createSlice } from '@reduxjs/toolkit';
import { brand } from '~src/brand/brand';

const userRewardsData = brand.pages.dashboard.userRewardsInfo;

const initialState = {
  walletCoins: [],
  walletCoinState: {
    loading: true,
    error: false,
  },
  userRewardsData,
  rewardsState: {
    hasRewards: false,
    hasMintableRewards: false,
  },
  ethWallet: {},
  ethWalletState: {
    loading: true,
  },
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletLoading: (state, action) => {
      state.walletCoinState.loading = action.payload;
    },
    setWalletState: (state, action) => {
      state.walletCoinState.loading = false;
      //check payload array for ETH coin
      const eth = action.payload.find((coin) => coin?.coinSymbol === 'ETH');
      if (eth !== undefined) {
        //due to brand tokens being set during user creation, we only want to update the wallet state if the ETH value is included
        state.walletCoins = action.payload;
        //set the purchase page wallet state specifically for that ETH object structure
        state.ethWallet = {
          id: eth.walletId,
          name: brand.brandName,
          icon: eth.coinLogo,
          totalUSD: eth.usd,
          totalETH: parseFloat(Number(eth.coinAmt).toFixed(6)),
          valid: eth.valid,
        };
        state.ethWalletState.loading = false;
      }
    },
    setHasWalletError: (state, action) => {
      state.walletCoinState.error = action.payload;
    },
    setUserRewardsData: (state, action) => {
      state.userRewardsData.recentReward.nodeDataOne =
        action.payload.userRewards.recentRewards;
      state.userRewardsData.availableReward.nodeDataOne =
        action.payload.userRewards.unmintedRewards;
      state.userRewardsData.totalReward.nodeDataOne =
        action.payload.userRewards.totalRewards;
      state.rewardsState.hasRewards = action.payload.userRewards.totalRewards > 0;
      state.rewardsState.hasMintableRewards =
        action.payload.userRewards.unmintedRewards > 0;
    },
    setEthWallet: (state, action) => {
      state.ethWallet.id = action.payload.id;
      state.ethWallet.name = action.payload.name;
      state.ethWallet.icon = action.payload.icon;
      state.ethWallet.totalUSD = action.payload.totalUSD;
      state.ethWallet.totalETH = action.payload.totalETH;
      state.ethWallet.valid = action.payload.valid;
    },
    setEthWalletState: (state, action) => {
      state.ethWalletState.loading = action.payload.loading;
    },
    clearWallet: (state) => {
      state.walletCoins = [];
      state.walletCoinState = {
        loading: true,
        error: false,
      };
      state.userRewardsData = userRewardsData;
      state.rewardsState = {
        hasRewards: false,
        hasMintableRewards: false,
      };
      state.ethWallet = {};
      state.ethWalletState = {
        loading: true,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setWalletLoading,
  setWalletState,
  setHasWalletError,
  setUserRewardsData,
  setEthWallet,
  setEthWalletState,
  clearWallet,
} = walletSlice.actions;

export default walletSlice.reducer;
