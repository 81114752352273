import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { requestCoinConversion } from '~src/resources/exchange.resource';
import { utils } from 'ethers';
import { config } from '~config';

const defaultData = {
  eth: {
    contractAddress: null,
    conversions: {
      usd: null
    },
    toAtomicUnit: (nominalAmount) => {
      return utils.parseEther(nominalAmount.toString());
    }
  },
  usdc: {
    contractAddress: config.blockchain.erc20Contracts.usdc,
    conversions: {
      usd: 1.00
    },
    toAtomicUnit: (nominalAmount) => {
      return nominalAmount * 1000000;
    }
  },
  give: {
    contractAddress: config.blockchain.erc20Contracts.give,
    conversions: {
      usd: null
    },
    toAtomicUnit: (nominalAmount) =>  {
      return nominalAmount * 100000000
    }
  },
  btc: {
    contractAddress: null,
    conversions: {
      usd: null
    },
    toAtomicUnit: (nominalAmount) => {
      return nominalAmount; // TODO, this might not be right...
    }
  }
};

const getTransferAmount = (symbol, amount) => {
  return defaultData[symbol.toLowerCase()]?.toAtomicUnit(amount) ?? null;
};

const getContractAddress =(symbol) => {
  return defaultData[symbol.toLowerCase()]?.contractAddress;
};

export const ExchangeContext = createContext({
  isLoading: false,
  error: null,
  data: defaultData,
  loadInfo: async () => {},
  getUsdValue: (symbol, amount) => null,
  getCoinValue: (symbol, usdAmount) => null,
  getTransferAmount,
  getContractAddress
});

export function ExchangeProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(defaultData);

  let intervalHandle = useRef(null);

  const getUsdValue = useCallback((symbol, amount) => {
    const toUsd = data[symbol?.toLowerCase()]?.conversions.usd ?? null;
    if (toUsd === null) {
      return null;
    }
    return amount * toUsd;
  }, [data]);

  const getCoinValue = useCallback((symbol, usdAmount) => {
    const toUsd = data[symbol?.toLowerCase()]?.conversions.usd ?? null;
    if (toUsd === null) {
      return null;
    }
    return usdAmount / toUsd;
  }, [data]);

  const loadInfo = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      let ethToUsd = await requestCoinConversion("ETH", "USD");
      let btcToUsd = await requestCoinConversion("BTC", "USD");
      let giveToUsd = await requestCoinConversion("GIVE", "USD");

      setData((prev) => {
        let newData = {...prev};

        newData.btc.conversions.usd = btcToUsd;
        newData.eth.conversions.usd = ethToUsd;
        newData.give.conversions.usd = giveToUsd;

        return newData;
      });
      setIsLoading(false);
    } catch (e) {
      console.error('Failed to load exchange data: ', e);
      setIsLoading(false);
      setData(defaultData);
      setError(e);
    }
  }, [])

  useEffect(() => {
    loadInfo().then(() => {

    }).catch(err => {
      console.error("Failed to load exchange information: ", err);
    });
    intervalHandle.current = setInterval(loadInfo, 300000);

    return () => {
      if (intervalHandle && intervalHandle.current) {
        clearInterval(intervalHandle.current);
      }
    };
  }, [loadInfo]);

  const value = useMemo(
    () => ({
      isLoading,
      error,
      data,
      loadInfo,
      getUsdValue,
      getCoinValue,
      getTransferAmount,
      getContractAddress
    }),
    [isLoading, error, data, loadInfo, getUsdValue, getCoinValue]
  );

  return <ExchangeContext.Provider value={value}>{children}</ExchangeContext.Provider>;
}
