import { config } from '../../config';

export function getAvailableProducts() {
  return {
    method: 'GET',
    url: `${config.core.shoppingServiceUrl}/api/products`,
  };
}

export function createInvoice({ products }) {
  return {
    method: 'POST',
    url: `${config.core.shoppingServiceUrl}/api/invoices?core=true`,
    data: {
      products: products,
    },
  };
}

export function submitInvoiceForPayment({ invoiceId, passcode, symbol }) {
  return {
    method: 'POST',
    url: `${config.core.shoppingServiceUrl}/api/invoices/${invoiceId}/payments`,
    data: {
      walletPassword: passcode,
      coinSymbol: symbol,
    },
  };
}

export function queryInvoiceStatus({ invoiceId }) {
  return {
    method: 'GET',
    url: `${config.core.shoppingServiceUrl}/api/invoices/${invoiceId}`,
  };
}

export function getProductByID({ productId }) {
  return {
    method: 'GET',
    url: `${config.core.shoppingServiceUrl}/api/products/${productId}`,
    };
}

export function getUserPurchases() {
  return {
    method: 'get',
    url: `${config.core.shoppingServiceUrl}/api/purchases/summary`,
  };
}

export function getCart() {
  return {
    method: 'get',
    url: `${config.core.shoppingServiceUrl}/api/cart`,
  };
}

export function addToCart({ productId, quantity }) {
  return {
    method: 'post',
    url: `${config.core.shoppingServiceUrl}/api/cart`,
    data: {
      productId,
      quantity,
    },
  };
}

export function removeFromCart({ productId, quantity }) {
  return {
    method: 'delete',
    url: `${config.core.shoppingServiceUrl}/api/cart/${productId}/${quantity}`,
  };
}

export function clearUserCart() {
  return {
    method: 'delete',
    url: `${config.core.shoppingServiceUrl}/api/cart`,
  };
}
