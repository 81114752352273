import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// Hooks
import { useAuth } from '../hooks/useAuth';
// Utils
import { addToCart, clearUserCart, getCart, removeFromCart } from '../resources/shopping-service.resource';
import { getGasFee } from '../resources/wallet-service.resource';
import { getCartTotals } from '../utils/cart';
import { routes } from '../routes/routes';

const Cart = React.createContext({});

export const useCartContext = () => useContext(Cart);

const initCartState = {
  cartError: { error: false, message: '' },
  cartItems: [],
  cartLoading: false,
  cartTotals: {
    cartQuantity: 0,
    cartSubtotal: 0,
    cartEthSubtotal: 0,
  },
};

const CartProvider = ({ children }) => {
  const [{ cartError, cartItems, cartLoading, cartTotals }, setCartState] =
    useState(initCartState);
  const [showAddToModal, setShowModal] = useState(false);
  const [gasFee, setGasFee] = useState({});
  const { fetcher, status, authStatuses } = useAuth();
  const router = useRouter();

  const handleSetCartState = (cartData) =>
    setCartState((prev) => ({ ...prev, ...cartData }));

  const clearCartError = () =>
    handleSetCartState({ cartError: { error: false, message: '' } });

  const handleGetGasFee = async () =>
    await fetcher(getGasFee('ETH' )).then((data) => {
        //console.log("Got gas fees: ", data);
        setGasFee({ eth: data.eth, usd: data.usd })
      }
    );

  useEffect(() => {
    if (status !== authStatuses.SIGNED_IN) {
      setCartState(initCartState);
      return;
    }
    // Run on user login
    handleSetCartState({ cartLoading: true });
    handleGetGasFee().then(getUserCart);
  }, [status]);

  const getUserCart = async () =>
    await fetcher(getCart())
      .then(({ cartItems }) => {
        let products = cartItems?.map((item) => {
          return {
            ...item,
            productEthPrice: item.productPriceInEth,
            subtotal: item.productPrice * item.quantity,
            ethSubtotal: item.productPriceInEth * item.quantity,
            productImage: 'https://via.placeholder.com/150',
          };
        });
        //console.log('Products: ', products);
        handleSetCartState({
          cartError: { error: false, message: '' },
          cartItems: products,
          cartLoading: false,
          cartTotals: getCartTotals(products),
        });
      })
      .catch(() => {
        handleSetCartState({
          cartError: { error: true, message: 'GET Cart error message!' },
          cartLoading: false,
        });
      });

  const addItemToCart = async ({ productId, quantity, showModal = false }) => {
    handleSetCartState({ cartLoading: true });
    return await fetcher(addToCart({ productId, quantity }))
      .then(getUserCart)
      .then(() => {
        console.log("Added to cart successfully.")
        showModal && setShowModal(true)
      })
      .catch(() =>
        handleSetCartState({
          cartError: { error: true, message: 'Error adding item!' },
          cartLoading: false,
        })
      );
  };

  const removeItemFromCart = async ({ productId, quantity }) => {
    handleSetCartState({ cartLoading: true });
    return await fetcher(removeFromCart({ productId, quantity }))
      .then(getUserCart)
      .catch(() =>
        handleSetCartState({
          cartError: { error: true, message: 'Error removing item!' },
          cartLoading: false,
        })
      );
  };

  const clearCart = async () =>
    await fetcher(clearUserCart())
      .then(() => {
        setCartState(initCartState)
        console.log("Cart cleared successfully")
      })
      .catch(() =>
        handleSetCartState({
          cartError: { error: true, message: 'Error clearing cart!' },
          cartLoading: false,
        })
      );

  const handleCloseAddToModal = () => {
    setShowModal(false);
  };

  const createPendingTransaction = (productId, quantity) => {
    const productPath = router.asPath;
    localStorage.setItem(
      'cartItems',
      JSON.stringify({
        productId,
        quantity,
        path: productPath,
      })
    );

    return router.push({
      pathname: routes.login.path,
      query: { redirect: productPath, id: router.query.id, cart: true },
    });
  };

  useEffect(() => {
    const productInStorage = localStorage.getItem('cartItems');
    if (!productInStorage) return;

    if (!!router.query?.login && status === authStatuses.SIGNED_IN) {
      addItemToCart({ ...JSON.parse(productInStorage) });
    }
  }, [status, router]);

  return (
    <Cart.Provider
      value={{
        addItemToCart,
        cartError,
        cartItems,
        cartLoading,
        cartTotals,
        clearCart,
        clearCartError,
        createPendingTransaction,
        gasFee,
        handleCloseAddToModal,
        handleGetGasFee,
        showAddToModal,
        removeItemFromCart,
      }}
    >
      {children}
    </Cart.Provider>
  );
};

export default CartProvider;
