import { config } from '~config';

export const getTokenRequest = () => {
  return {
    method: "GET",
    url: config.backend.giveUrl + "/sardine/get-token"
  }
}

export const getOrderStatusRequest = (orderId) => {
  return {
    method: "GET",
    url: `${config.backend.giveUrl}/sardine/order-status?orderId=${orderId}`
  }
}

export function getTotalPoints() {
  return {
    method: 'GET',
    url: `${config.backend.giveUrl}/action/total-points`,
  };
}

export function getGiveUsdPrice() {
  return {
    method: 'GET',
    url: `${config.backend.giveUrl}/token/price`
  }
}

export function getTaxOverview() {
  return {
    method: 'GET',
    url: `${config.backend.giveUrl}/giver/tax-receipt/overview`
  }
}

export function getTaxReceipt(year) {
  return {
    method: 'GET',
    url: `${config.backend.giveUrl}/giver/tax-receipt/${year}`
  }
}

export const checkForNonprofitEmail = (email) => {
  return {
    method: 'GET',
    url: `${config.backend.giveUrl}/onboarding/nonprofit/check/${email}`
  };
};

export const confirmNonprofitEmail = (id, emailToken) => {
  return {
    method: 'PUT',
    url: `${config.backend.giveUrl}/onboarding/nonprofit/confirmEmail/${id}?emailToken=${emailToken}`
  }
}

export const getNonprofitApplication = (id) => {
  return {
    method: "GET",
    url: `${config.backend.giveUrl}/onboarding/nonprofit/${id}`
  }
}

export const linkAccountToNonprofit = (id, emailToken) => {
  return {
    method: 'PUT',
    url: `${config.backend.giveUrl}/onboarding/nonprofit/linkAccount/${id}?emailToken=${emailToken}`
  }
}

export const approveNonprofitApplication = (id) => {
  return {
    method: "PUT",
    url: `${config.backend.giveUrl}/onboarding/nonprofit/approve/${id}`
  }
}

export const rejectNonprofitApplication = (id, rejectedReason) => {
  return {
    method: "PUT",
    url: `${config.backend.giveUrl}/onboarding/nonprofit/reject/${id}`,
    body: new FormData({
      rejectedReason
    })
  }
}

export const getSignupDetailsForNonprofit = (id, emailToken) => {
  return {
    method: "GET",
    url: `${config.backend.giveUrl}/onboarding/nonprofit/signup-details/${id}?emailToken=${emailToken}`
  }
}

export const getStripeConnectAccountStatus = () => {
  return {
    method: "GET",
    url: `${config.backend.giveUrl}/stripe/onboarding`
  }
}

export const startStripeConnectProcess = () => {
  return {
    method: "POST",
    url: `${config.backend.giveUrl}/stripe/onboarding`
  }
}

export const getNonprofitStripeAccount = (nonprofitId) => {
  return {
    method: "GET",
    url: `${config.backend.giveUrl}/public/stripe/payment/${nonprofitId}`
  }
}

export const createStripeDonationIntent = (nonprofitId, amount) => {
  return {
    method: "POST",
    url: `${config.backend.giveUrl}/public/stripe/payment`,
    data: {
      nonprofitId,
      amount,
      currency: "usd"
    },
    headers: {
      'Content-Type': 'application/json'
    }
  }
}

export const claimGiftWithToken = (giftId, email, claimToken) => {
  return {
    method: 'POST',
    url: `${config.backend.giveUrl}/public/gift/claim/${giftId}/${email}?claimToken=${claimToken}`
  }
}

export const getGiftById = (giftId) => {
  return {
    method: 'GET',
    url: `${config.backend.giveUrl}/gift/${giftId}`
  }
}